<template>
  <AtroContent
    :class="[
      'mx-auto h-full w-full flex-1 space-y-8',
      { 'px-2 py-5 sm:px-12 sm:py-8': padded },
      { 'max-w-[var(--detail-width)]': !fullWidth },
    ]"
    direction="col"
    wrap="nowrap"
    justify="center"
  >
    <ToolbarTop
      v-if="hasTopToolbar"
      :center-icon-wrapped
      :center-icon="topToolbarIcon || 'gear-outline'"
      :center-text="toolbarText"
    >
      <template #left v-if="hasBackToDashboard">
        <slot name="leftToolbarSlot">
          <ButtonBackToDashboard />
        </slot>
      </template>

      <template #right>
        <slot name="rightToolbarSlot" />
      </template>
    </ToolbarTop>

    <AtroContent class="relative h-full w-full flex-1">
      <MainContentCard class="h-full" justify="start">
        <AsideContent
          v-if="!$isMobile.value && hasAside"
          class="space-y-2 justify-self-start"
          position="left"
        >
          <AtroContent
            v-if="$slots.asidePrefix"
            class="mb-2 w-full"
            direction="col"
          >
            <slot name="asidePrefix" />
          </AtroContent>
          <slot name="asideContent">
            <NuxtLink
              v-for="item in navItems"
              :key="item.label"
              :to="item.path"
              class="group inline-flex w-full"
            >
              <AtroButton
                :class="{
                  '!text-primary': route.path === item.path,
                }"
                type="flatGray"
                :icon-left="item.icon"
                :text="item.label"
              />
            </NuxtLink>
          </slot>
          <slot name="asidePostfix" />
        </AsideContent>
        <MainContent
          :class="{ 'h-full': fullHeight }"
          :width="fullWidth || !hasAside ? 'full' : 'md'"
        >
          <slot />
        </MainContent>
      </MainContentCard>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  centerIconWrapped?: boolean
  fullHeight?: boolean
  fullWidth?: boolean
  hasBackToDashboard?: boolean
  hasTopToolbar?: boolean
  navItems?: NavItem[]
  padded?: boolean
  title?: string
  topToolbarIcon?: IconName
}

const {
  navItems,
  title,
  centerIconWrapped = true,
  hasTopToolbar = true,
  hasBackToDashboard = true,
  padded = true,
} = defineProps<Props>()
const isMobile = useIsMobile()
const route = useRoute()
const slots = useSlots()

const activeNavItem = computed(() =>
  navItems?.find((item) => item.path === route.path),
)

const hasAside = computed(
  () =>
    navItems?.length ||
    slots.asideContent ||
    slots.asidePrefix ||
    slots.asidePostfix,
)

const toolbarText = computed(() => {
  if (isMobile.value && activeNavItem.value) {
    return activeNavItem.value.label
  } else if (title) {
    return title
  }
  return ''
})
</script>
